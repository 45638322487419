.imageContentGallery {

	@include media($largeLayout) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}


	&__item {
		display: flex;
		align-items: flex-start;

		@include media($smallLayout) {
			align-items: center;
		}

		@include media($largeLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
			align-items: flex-start;
		}


		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
		}

		&:not(:last-of-type) {
			padding-bottom: $base3;
			@include media($largeLayout) {
				padding-bottom: $base3 + $baseHalf;
			}
		}


		& + & {
			padding-top: $base3;
			border-top: solid $borderSize $borderColorAlternate;

			@include media($largeLayout) {
				border-top: none;
				padding-top: 0;
			}
		}

		&:nth-child(n+2) + & {
			@include media($largeLayout) {
				padding-top: $base3 + $baseHalf;
				border-top: solid $borderSize $borderColorAlternate;
			}
		}

		&:nth-child(odd) {
			@include media($largeLayout) {
				margin-right: $gutterMedium;
			}

			@include media($xLargeLayout) {
				margin-right: $gutterLarge;
			}
		}

		.imageContentGallery--exhibitions &:nth-child(odd) {
			@include media($largeLayout) {
				margin-right: 0;
			}
		}

		.imageContentGallery--exhibitions & {
			@include media($largeLayout) {
				width: 100%;
				align-items: center;
			}
		}

		.imageContentGallery--exhibitions & + & {
			padding-top: $base3;
			border-top: solid $borderSize $borderColorAlternate;
		}
	}


	&__content {
		&--img {
			width: staticColumnSize(5, 12, $gutterSmall);
			margin-right: $gutterSmall;
			margin-top: topLineHeight('thirdHeadline');

			@include media($smallLayout) {
				margin-top: 0;
				width: staticColumnSize(6, 12, $gutterMedium);
				margin-right: $gutterMedium;
			}

			@include media($mediumLayout) {
				width: staticColumnSize(5, 12, $gutterMedium);
				margin-right: staticColumnSize(1, 12, $gutterMedium, $gutterMedium * 2);
			}

			@include media($largeLayout) {
				width: staticColumnSize(3, 6, $gutterMedium);
				margin-right: $gutterMedium;
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(3, 6, $gutterLarge);
				margin-right: $gutterLarge;
			}

			.imageContentGallery--exhibitions & {
				@include media($largeLayout) {
					width: staticColumnSize(6, 12, $gutterMedium);
					margin-right: $gutterMedium;
				}

				@include media($xLargeLayout) {
					width: staticColumnSize(6, 12, $gutterLarge);
					margin-right: $gutterLarge;
				}
			}
		}

		&--img + &--text {
			width: staticColumnSize(7, 12, $gutterSmall);

			@include media($smallLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(5, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(3, 6, $gutterMedium);
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(3, 6, $gutterLarge);
			}

			.imageContentGallery--exhibitions & {
				@include media($largeLayout) {
					width: staticColumnSize(6, 12, $gutterMedium);
				}

				@include media($xLargeLayout) {
					width: staticColumnSize(6, 12, $gutterLarge);
				}
			}
		}

		&--text {
			width: 100%;
		}
	}
}
