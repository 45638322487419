.text {
	@include font(base);
	color: $colorBase;


	&--firstHeadline {
		@include font('firstHeadline');
		margin-bottom: $base1;
	}


	&--secondHeadline {
		@include font('secondHeadline');
		margin-bottom: $base1;

		.application & {
			margin-bottom: $base2;


			@include media($mediumLayout) {
				margin-bottom: $base3;
			}
		}

		.highlight & {
			margin-bottom: $base2;
		}

		.application--alternate & {
			margin-bottom: $applicationAlternateSpacing;
		}

		.application--jobs & {
			margin-bottom: $base1;
		}

		.descendantList__content & {
			@include media($mediumLayout) {
				margin-bottom: $base2;
			}
		}
	}


	&--thirdHeadline {
		@include font('thirdHeadline');
		margin-bottom: $base1;

		.link & {
			margin-bottom: 0;
		}
	}


	&--fourthHeadline {
		@include font('fourthHeadline');
		margin-bottom: $base1;
	}


	&--title {
		@include font('baseAlternate');
	}


	&--detail {
		@include font('detail');

		.application__info & + & {
			margin-left: $baseHalf;
		}

		.field__label--checkbox & {
			padding-top: $fieldLabelPadding;
			padding-left: $baseHalf;
			padding-right: $baseHalf;
		}

		input[type="checkbox"] + .field__label & {
			@include ease(background-color opacity, $hoverDuration);
			background: $colorBgInput;
		}

		input[type="checkbox"]:checked + .field__label & {
			background: $colorBgInverted;
			color: $colorInverted;
		}

		input:focus + .field__label & {
			outline: solid $inputBorderSize $hoverColorAlternate;
		}

		html:not(.mobile) input:hover + .field__label & {
			opacity: 0.5;
		}

		.imageContentGallery & {
			margin-bottom: $base1;
		}
	}


	&--detailHighlighted {
		@include font('detailHighlighted');
		margin-left: $baseHalf;

		.application--jobs &,
		.newsPreviews &,
		.highlight & {
			margin-left: 0;
		}
	}

	.application__date & {
		@include font('application');
	}

	.application__date &--detailHighlighted {
		@include font('applicationHighlighted');
	}


	&--separatedDetail {
		margin-right: $baseHalf;

		&::after {
			@include font('detail');
			content: '\002F';
			margin-left: $baseHalf;
		}
	}

	&--description {
		margin-bottom: $base2;

		@include media($xLargeLayout) {
			margin-bottom: $base3;
		}
	}


	&--link {
		.application--alternate & {
			color: $colorBase;
		}
	}
}
