@mixin app-link($inverted: false) {
	@include ease(border-color color, $hoverDuration);
	display: inline-block;
	color: $colorBase;
	text-decoration: none;

	& .text,
	& .link__label {
		@include ease(border-color color, $hoverDuration);
		display: inline;
		border-bottom: 1px $colorUnderline solid;
	}

	@include onHover() {
		color: $hoverColor;

		& .text,
		& .link__label {
			color: $hoverColor;
			border-bottom: 1px $hoverColor solid;
		}
	}

	&:focus {
		outline: none;

		& .text,
		& .link__label {
			outline: solid 1px $hoverColor;
			border-bottom: 1px transparent solid;
		}
	}

	@if ($inverted) {
		color: $colorInverted;
		border-bottom: solid 1px $colorInverted;

		& .text,
		& .link__label {
			display: inline;
			border-bottom: 1px $colorUnderline solid;
		}

		&:focus {
			outline: solid 1px $colorInverted;

			& .text,
			& .link__label {
				outline: solid 1px $colorInverted;
				border-bottom: 1px transparent solid;
			}
		}

		@include onHover() {
			color: $hoverColor;

			& .text,
			& .link__label {
				color: $hoverColor;
				border-bottom: 1px $hoverColor solid;
			}
		}
	}
}
