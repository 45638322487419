.footer {
	@include fadeIn($fadeDuration);
	background-color: $colorBgInverted;
	color: $colorInverted;
	position: relative;

	.body[data-current-context="menu"] & {
		@include fadeOut($fadeDuration);
	}

	&.js-followed {
		@include app-covered();
	}

	&__container {
		@include app-container();
		padding-top: $base4;

		@include media($smallLayout) {
			padding-top: $base5;
		}

		@include media($mediumLayout) {
			display: flex;
		}

		@include media($xLargeLayout) {
			padding-top: $base10 + $base5;
		}

		&--alternate {
			background-color: $colorBgBase;
			outline-color: $colorBgBase;
			outline-style: solid;
			outline-width: 1000px;
			clip-path: inset(0 -100vw 0 -100vw);

			@include media($xLargeLayout) {
				padding-top: $base5;
			}
		}
	}


	&__adress {
		@include media($smallLayout) {
			margin-right: $gutterMedium;
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(4, 8, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(4, 8, $gutterLarge);
			margin-right: $gutterLarge;
		}
	}


	&__contact {
		@include media($smallLayout) {
			display: flex;
			margin-bottom: $base8;
		}

		@include media($mediumLayout) {
			margin-bottom: $base7;
			width: staticColumnSize(8, 8, $gutterMedium);
		}


		@include media($xLargeLayout) {
			width: staticColumnSize(8, 8, $gutterLarge);
		}
	}


	&__contactList {
		margin-bottom: $base5;

		@include media($smallLayout) {
			margin-bottom: 0;
		}
	}

	&__contactListItem {
		display: flex;

		& + & {
			margin-top: $base2;
		}
	}


	&__content {
		@include media($mediumLayout) {
			width: staticColumnSize(8, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
		}


		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
		}

		&--logo {
			margin-bottom: $base3;

			@include media($smallLayout) {
				margin-bottom: $base5;
			}

			@include media($mediumLayout) {
				width: staticColumnSize(4, 12, $gutterMedium);
				margin-right: $gutterMedium;
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
				margin-right: $gutterLarge;
			}
		}
	}


	&__definitionDetail {
		margin-left: 0;

		.footer__definitionTerm + & {
			margin-bottom: $base2;
		}
	}


	&__definitionList {
		margin-bottom: $base2;
	}


	&__definitionTerm {
		// margin-bottom: $baseHalf;
	}


	&__newsletterSubscribe {
		margin-bottom: $base5;
	}


	&__link {
		display: inline-block;
		color: $colorInverted;
		text-decoration: none;

		&:focus {
			outline: none;
		}

		&--logo {
			@include ease(opacity, $hoverDuration);
			border: none;
			width: 100%;

			@include onHover() {
				opacity: $imageOpacity;
			}

			@include media($smallLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(4, 8, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(3, 6, $gutterLarge);
			}
		}

		&--logo + &--logo {
			margin-top: $base3;

			@include media($smallLayout) {
				margin-top: 0;
				margin-left: $gutterMedium;
			}

			@include media($largeLayout) {
				margin-left: $gutterLarge;
			}
		}


		.footer__contactListItem & {
			margin-left: $baseHalf;
		}
	}


	&__linkList {
		margin-bottom: $base5;

		@include media($smallLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
			margin-bottom: 0;
		}

		@include media($mediumLayout) {
			width: staticColumnSize(4, 8, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(4, 8, $gutterLarge);
		}
	}


	&__linkListItem {
		& + & {
			margin-top: $base2;
		}
	}


	&__logos {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: $base1 * 15;

		@include media($smallLayout) {
			padding-bottom: $base10;
		}

		@include media($mediumLayout) {
			width: staticColumnSize(8, 12, $gutterMedium);
			margin-left: staticColumnSize(4, 12, $gutterMedium, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
			margin-left: staticColumnSize(6, 12, $gutterLarge, $gutterLarge);
		}
	}


	&__text {
		@include ease(color, $hoverDuration);
		@include font(detail);

		@include onHover('.footer__link', '&') {
			color: $hoverColor;
		}

		.footer__link & {
			@include app-link(true);
			display: inline;
			vertical-align: top;
		}

		.footer__link:focus & {
			outline: solid 1px $colorInverted;
			border-bottom: 1px transparent solid;
		}

		.footer__logos & {
			color: $colorBase;
			margin-bottom: $base3;
			width: 100%;

			@include media($smallLayout) {
				width: staticColumnSize(12, 12, $gutterMedium);
			}
		}
	}
}
