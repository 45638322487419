.slideshow {
	@include fadeOut($duration4);
	flex-direction: column;
	align-items: center;
	position: relative;
	height: 100%;
	display: none;

	&.js-beforeActive {
		display: flex;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}


	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		touch-action: pan-y;
		position: relative;
		height: 100%;
		width: staticColumnSize(10, 12, $gutterMedium);
	}
}
