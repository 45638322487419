.form {
	position: relative;


	&__buttons {
		// this removes the space between inline-blocks / inline-flex elements.
		// the proper font size is set in the children
		font-size: 0;
		margin-left: -$base2;
		margin-bottom: -$base2;

		* + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}
	}


	&__fields {
		position: relative;

		&--filter {
			display: flex;
			flex-wrap: wrap;
		}
	}


	&__separator {
		background-color: currentColor;
		border: 0;
		height: $boxBorderSize;
		margin-bottom: 0;
		display: block;
		width: 100%;


		* + & {
			margin-top: $base4;
		}
	}


	&__text {
		.form__buttons & {
			@include font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > * + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}
	}
}
