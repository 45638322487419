@mixin app-linkButton() {
	@include ease(background-color, $hoverDuration);
	@include font('thirdHeadline');
	background-color: $colorBgInverted;
	color: $colorInverted;
	display: inline-block;
	padding-bottom: $base * 0.6;
	padding-left: $base2;
	padding-right: $base2;
	padding-top: $base * 0.6;
	text-decoration: none;

	@include onHover() {
		background-color: $hoverColorAlternate;
	}

	&:focus {
		outline: solid $borderSize $hoverColor;
	}
}
