.separator {
	background-color: $colorBase;
	border: 0;
	height: $borderSize;
	margin-bottom: $base1;
	margin-top: 0;
	width: 100%;

	@include media($xSmallLayout) {
		margin-bottom: $base2;
	}

	@include media($smallLayout) {
		margin-bottom: 0;
		margin-right: $gutterMedium;
		margin-top: (getFontSize('detail') * getLineHeight('detail')) / 2;
		width: staticColumnSize(4, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		width: staticColumnSize(4, 12, $gutterLarge);
		margin-right: $gutterLarge;
	}

	&--content {
		@include mediaMap($sizeVariants, sectionSpacingsAlternate);
		width: 100%;

		@include media($smallLayout) {
			margin-top: 0;
		}

		@include media($largeLayout) {
			margin-top: (getFontSize('base') * getLineHeight('base')) / 2;
		}
	}

	&--section {
		width: 100%;
		margin-top: 0;
		margin-right: 0;
	}
}
