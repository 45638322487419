.slideshowNavigation {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);

	&__icon {
		@include ease(background-color, $duration2);
		color: $colorInverted;
		display: inline-block;
		position: relative;
		pointer-events: none;
		border: 0;

		.slideshowNavigation__link.js-current & {
			background-color: currentColor;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__item {
		line-height: 0;
		margin-right: $base2;

		&:last-child {
			margin-right: 0;
		}

		&--prev,
		&--next {
			html.no-js & {
				display: none;
			}
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.section--cover & {
			justify-content: space-between;
			@include media($largeLayout) {
				width: staticColumnSize(7, 12, $gutterMedium);
			}
		}

		.modal & {
			pointer-events: none;
			justify-content: space-between;
		}
	}


	&__button {
		@include ease(background-color, $hoverDuration);
		cursor: pointer;
		padding: $baseHalf;
		display: block;
		pointer-events: all;

		@include onHover() {
			background-color: $hoverColor;
		}

		&:focus {
			outline: solid $colorInverted 1px;
		}
	}
}
