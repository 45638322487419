
@mixin app-toggleContainer($apply: true) {
	padding-left: if($apply, $containerPaddingXSmall, 0);
	padding-right: if($apply, $containerPaddingXSmall, 0);
	max-width: if($apply, $containerMaxWidth, none);
	margin-left: auto;
	margin-right: auto;
	width: 100%;


	@include media($xSmallLayout) {
		padding-left: if($apply, $containerPaddingSmall, 0);
		padding-right: if($apply, $containerPaddingSmall, 0);
	}

	@include media($smallLayout) {
		padding-left: if($apply, $containerPaddingMedium, 0);
		padding-right: if($apply, $containerPaddingMedium, 0);
	}

	@include media($largeLayout) {
		padding-left: if($apply, $containerPaddingLarge, 0);
		padding-right: if($apply, $containerPaddingLarge, 0);
	}

	@include media($xLargeLayout) {
		padding-left: if($apply, $containerPaddingXLarge, 0);
		padding-right: if($apply, $containerPaddingXLarge, 0);
	}
}
