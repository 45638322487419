@mixin onHover($selector: '&', $applyOn: '') {
	html:not(.mobile) #{$selector}:hover #{$applyOn},
	#{$selector}:active #{$applyOn} {
		@content;
	}

	button:focus {
		@content;
	}
	button:focus:not(:focus-visible) {
	  /* Undo all the above focused button styles
	     if the button has focus but the browser wouldn't normally
	     show default focus styles */
	}

	button:focus-visible { /* Some even *more* exciting button focus styles */ }
}
