.modalToggler {
	@include ease(background-color, $hoverDuration);
	cursor: pointer;
	position: absolute;
	right: $containerPaddingMedium;
	top: $base2;
	width: $closeIconSpacings;
	height: $closeIconSpacings;
	padding: $baseHalf;
	pointer-events: all;
	z-index: 200;

	@include onHover() {
		background-color: $hoverColor;
	}

	&:focus {
		outline: solid $colorInverted 1px;
	}

	@include media($largeLayout) {
		right: $containerPaddingLarge;
	}

	@include media($xLargeLayout) {
		right: $containerPaddingXLarge;
	}


	.modal--alternate & {
		display: none;
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		width: $closeIconSpacings;
		height: $closeIconSpacings;
		color: $colorInverted;

		.modal--alternate & {
			color: $colorBase;
		}

		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}
}
