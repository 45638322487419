.application {
	&__date {
		display: inline-flex;
		align-items: baseline;

		@include media($xSmallLayout) {
			margin-left: $base2;
		}
	}

	&__detail {
		@include font('detail');
		margin-bottom: $baseHalf;
	}


	&__infos {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		@include media($xSmallLayout) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__info {
		display: flex;
		margin-bottom: $base1;

		&:not(:last-of-type) {
			margin-right: $base2;

			@include media($mediumLayout) {
				margin-right: $base3;
			}
		}
	}


	&__list {
		border-top: solid $borderSize $borderColor;
	}


	&__listItem {
		border-bottom: solid $borderSize $borderColor;
		padding-bottom: $base3;
		padding-top: $base1;

		.application--alternate & {
			padding-bottom: $base3 + $baseHalf;
		}

		.application--jobs & {
			padding-bottom: $base2;

			@include media($mediumLayout) {
				padding-top: $base2;
				padding-bottom: $base3;
			}
		}
	}
}
