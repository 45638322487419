.highlight {
	position: relative;

	@include media($smallLayout) {
		display: flex;
	}


	&__imageWrapper {
		margin-bottom: $base2;

		@include media($smallLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
			margin-bottom: 0;
			margin-right: $gutterMedium;
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
			margin-right: $gutterLarge;
		}
	}



	&__textContent {
		.highlight--alternate & {
			@include media($smallLayout) {
				width: staticColumnSize(8, 12, $gutterMedium);
				margin-bottom: 0;
				margin-right: $gutterMedium;
			}

			@include media($mediumLayout) {
				width: staticColumnSize(7, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
				margin-right: $gutterLarge;
			}
		}

		@include media($smallLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&__textWrapper {
		display: flex;
		margin-bottom: $base1;
	}

	&__text {
		@include font('base');

		&--detail {
			@include font('detail');
		}

		&--highlighted {
			font-weight: bold;
		}

		&--headline {
			@include font('secondHeadline');
			margin-bottom: $base2;
		}
	}
}
