.exhibitionTitle {
	&__date {
		@include font('secondHeadline');
	}

	&__headline {
		@include font('baseAlternate');


		@include media($mediumLayout) {
			margin-top: $base1;
		}
	}
}
