.accordion {
	position: relative;



	&__content {
		@include ease(opacity visibility, $duration4);
		left: 0;
		padding-top: $base;
		position: relative;
		right: 0;
		top: 0;

		&:focus {
			outline: 0;
		}

		&.js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;

			html.no-js &:focus-within {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}

			@include media($largeLayout) {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}
		}

		.filters & {
			padding-top: $base2;
			padding-bottom: $base2;

			@include media($mediumLayout) {
				padding-bottom: 0;
			}
		}

		.filters &.js-collapsed {
			@include media($mediumLayout) {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}
		}
	}


	&__contentWrapper {
		@include ease(height, $duration4);
		margin: 0 0 $base2 0;
		position: relative;
		width: 100%;
		overflow: hidden;


		.filters & {
			margin: 0;
		}
	}


	&__toggler {
		align-items: center;
		border-bottom: solid $borderSize $colorBase;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-bottom: $base1;
		position: relative;
		width: 100%;

		.filters & {
			padding-bottom: $base1;
			padding-top: $base1;

			@include media($mediumLayout) {
				padding-bottom: $base2;
			}
		}
	}


	&__togglerExtraInfo {
		order: 2;
		position: relative;
		z-index: 4;
	}


	&__togglerIcon {
		@include ease(transform, $duration4);
		display: inline-block;
		flex-grow: 0;
		height: $togglerIconSize;
		line-height: 0;
		margin-right: $base / 2;
		position: relative;
		width: auto;
		transform: rotate(45deg);
		// z-index: 2;

		@include media($largeLayout) {
			display: none;
		}

		.filters & {
			@include media($mediumLayout) {
				display: none;
			}
		}

		.accordion__togglerLink.js-toggled ~ & {
			transform: rotate(90deg);
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__togglerLabel {
		@include font('base');
		display: inline-block;
		position: relative;
	}


	&__togglerLink {
		color: $colorBase;
		flex-grow: 1;
		font-weight: bold;
		height: 100%;
		text-decoration: none;

		@include media($largeLayout) {
			pointer-events: none;
		}

		.filters & {
			@include media($mediumLayout) {
				pointer-events: none;
			}
		}

		&.js-toggled::before {
			background-color: transparent;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: transparent;
			z-index: 3;
		}
	}
}
