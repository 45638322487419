.fields {
	&--group {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.filters__item--date &--group {
		flex-wrap: nowrap;
	}

	&__groupLabel {
		padding-left: $base2;
		padding-right: $base2;
	}
}
