// BASE DEFINITION
$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base / 2;

$videoRatio: 16 / 9;

// LAYOUT & CONTAINER

$containerPaddingXSmall: $base2;
$containerPaddingSmall: $base3;
$containerPaddingMedium: $base5;
$containerPaddingLarge: $base7;
$containerPaddingXLarge: $base * 10;

$containerMaxWidth: 152 * $base;

$gutterSmall: $base2;
$gutterMedium: $base3;
$gutterLarge: $base5;

$layoutGutter: $gutterMedium;


// BLOCK SPACINGS
$blockVerticalSpacing: $base4;
$firstBlockTopSpacing: $base8;
$applicationAlternateSpacing: 3.6rem;

$headerSpacingSmall: $base2;
$headerSpacingMedium: $base3;
$headerSpacingLarge: $base5;
$headerCollapsedSpacing: $base1 + $baseHalf;

$menuItemTopSpacingSmall: $base2;
$menuItemTopSpacingBig: $base3;

$modalTopSpacing: $base6 + $baseHalf;
$descendantListTopSpacing: $base2;

// MEDIA
$galleryColumnsImageRatio: 3 / 2;


//FORMS
$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$inputBorderSizeAlternate: 2px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.4;
$radioInnerScale: 0.5;

$fieldLabelPadding: 0.3rem;
$checkboxSize: $base2;


// BUTTON & LINKS
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;


// BORDERS; OUTLINES & SEPARATORS
$borderSize: 2px;
$borderSizeAlternate: 1px;
$hrSize: 1px;

$indicatorHeight: $base2 / 10;
$indicatorOffset: 10px;

$boxBorderSize: 2px;
$tableCellBorderSize: 1px;
$tableHeaderBorderSize: 2px;

// CONTENT
$textMaxWidth: $base * 100;


//LOGOS & ICONS
$logoHeightSmall: $base2 + 0.9rem;
$logoHeightMedium: $base4;
$logoHeightLarge: $base5;

$togglerIconSize: 1.6rem;
$closeIconSpacings: 2.4rem;


// VARIANTS
$legacyIconWidth: $base * 6.4;
$legacyIconHeight: $base * 1.6;

$sizeVariants: (
	headerVerticalSpacings: (
		padding-bottom: $headerSpacingSmall,
		padding-top: $headerSpacingSmall,

		variants: (
			$xSmallLayout: (
				padding-bottom: $headerSpacingMedium,
				padding-top: $headerSpacingMedium
			),

			$smallLayout: (
				padding-bottom: $headerSpacingLarge,
				padding-top: $headerSpacingLarge
			)
		)
	),

	mainMenuVerticalSpacings: (
		min-height: calc(100vh - (#{$logoHeightSmall} + #{$headerSpacingSmall} * 2)),
		variants: (
			$xSmallLayout: (
				min-height: calc(100vh - (#{$logoHeightMedium} + #{$headerSpacingMedium} * 2)),
			),
			$smallLayout: (
				min-height: calc(100vh - (#{$logoHeightMedium} + #{$headerSpacingLarge} * 2)),
			),
			$mainMenuSwitchBreakpoint: (
				min-height: unset,
				height: calc(100vh - (#{$logoHeightLarge} + #{$headerSpacingLarge} * 2)),
			)
		)
	),
	mainNavVerticalSpacings: (
		padding-top: $base9,
		variants: (
			$mediumLayout: (
				padding-top: $base1 * 10,
			),
			$mainMenuSwitchBreakpoint: (
				padding-bottom: $headerSpacingLarge,
				padding-top: $headerSpacingLarge
			)
		)
	),
	subNavVerticalOffset: (
		padding-top: $base1,
		variants: (
			$xSmallLayout: (
				padding-top: 0,
			),
		)
	),
	mainVerticalOffset: (
		padding-top: $logoHeightSmall + $headerSpacingSmall * 2,
		variants: (
			$xSmallLayout: (
				padding-top: $logoHeightMedium + $headerSpacingMedium * 2,
			),
			$smallLayout: (
				padding-top: $logoHeightMedium + $headerSpacingLarge * 2,
			),
			$mainMenuSwitchBreakpoint: (
				padding-top: $logoHeightLarge + $headerSpacingLarge * 2,
			)
		)
	),
	logoSpacings: (
		height: $logoHeightSmall,
		variants: (
			$xSmallLayout: (
				height: $logoHeightMedium,
			),
			$mainMenuSwitchBreakpoint: (
				height: $logoHeightLarge,
			),
		)
	),
	sectionSpacings: (
		margin-bottom: $base5,
		variants: (
			$mediumLayout: (
				margin-bottom: $base8,
			),
			$xLargeLayout: (
				margin-bottom: $base10,
			),
		)
	),
	sectionSpacingsAlternate: (
		margin-bottom: $base3,
		variants: (
			$mediumLayout: (
				margin-bottom: $base5,
			),
			$xLargeLayout: (
				margin-bottom: $base8,
			),
		)
	),
	blockSizing: (
		width: 100%,
		variants: (
			$smallLayout: (
				width: staticColumnSize(10, 12, $gutterMedium),
			),
			$mediumLayout: (
				width: staticColumnSize(9, 12, $gutterMedium)
			),
			$largeLayout: (
				width: staticColumnSize(6, 12, $gutterMedium)
			),
			$xLargeLayout: (
				width: staticColumnSize(6, 12, $gutterLarge)
			),
		)
	),
	blockSizingAlternate: (
		width: 100%,
		variants: (
			$smallLayout: (
				width: staticColumnSize(10, 12, $gutterMedium),
			),
			$mediumLayout: (
				width: staticColumnSize(9, 12, $gutterMedium)
			),
			$largeLayout: (
				width: staticColumnSize(8, 12, $gutterMedium)
			),
			$xLargeLayout: (
				width: staticColumnSize(8, 12, $gutterLarge)
			),
		)
	),
);
