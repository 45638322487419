.field {
	@include font(base);
	width: 100%;

	&--disabled {
		opacity: $opacityInputDisabled;
	}

	.fields--group & {
		width: auto;
		flex-grow: 1;
	}


	.form & {
		&--checkbox {
			width: staticColumnSize(6, 12, $gutterSmall);

			&:nth-child(odd) {
				margin-right: $gutterSmall;
			}

			&:nth-child(n+3) {
				margin-top: $base1;
			}
		}
	}

	.form__separator + & {
		margin-top: $base4;
	}



	&__content {
		.filters .field--filterSearch & {
			border-bottom: $inputBorderSizeAlternate solid $colorBase;
		}
	}

	&__description {
		margin-left: $base3;
	}


	// &__errors {
	// 	@include font(fieldError);
	// 	color: $colorError;
	// 	margin-left: $base3;
	// 	margin-top: $baseHalf;
	// }


	&__info {
		color: $colorInput;
		display: block;


		.field--radio &,
		.field--checkbox & {
			margin-left: $base3;
		}

		.field__label + & {
			margin-top: -$base;
		}
	}


	&__input {
		@include font(base);
		background-color: $colorBgInput;
		color: $colorInput;


		&--checkbox,
		&--radio {
			@include visuallyHidden();

			&:focus {
				outline-color: currentColor;
				outline-style: solid;
				outline-width: $inputBorderSize;
			}
		}

		&--text {
			@include font('firstHeadline');
			background-color: $colorBgBase;
			// border: 0;
			border-radius: 0;
			color: $colorBase;
			width: 100%;


			&::placeholder {
				color: $colorInputPlaceholder;
			}

			&:focus-within {
				outline: none;

				&::placeholder {
					color: transparent;
				}
			}
		}

		.field__inputWrapper--search &--text {
			border: none;
		}

		&--number {
			@include font('detail');
			appearance: none;
			border: none;
			height: $base4;
			padding: $base * 0.8 $base1;
			width: 100%;
		}

		.filters &--text {
			@include font('base');
			border: none;
			padding-bottom: 0;
			padding-left: $base2;
			padding-right: 0;
			padding-top: 0;
		}

		&--reset {
			width: 0;
			padding: 0;
			border: none;
			background-color: transparent;
		}

		&--select {
			@include font('detail');
			appearance: none;
			border: none;
			height: $base4;
			padding: $base * 0.8 $base1;
			width: 100%;
		}
	}


	&__inputWrapper {
		position: relative;

		&--search {
			display: flex;
			align-items: center;
			padding-bottom: $base1;
			border-bottom: solid $inputBorderSizeAlternate $colorBase;

			@include media($mediumLayout) {
				padding-bottom: $base2;
			}
		}

		.field--select & {
			display: flex;
		}

		.filters .field--filterSearch & {
			margin-bottom: $base1;

			@include media($mediumLayout) {
				margin-top: $base1;
				margin-bottom: $base2;
			}
		}

		.filters .field--radio & {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__inputOption {
		flex-grow: 1;
		width: 50%;

		.filters .field--radio & {
			width: staticColumnSize(6, 12, $gutterSmall);

			&:nth-child(odd) {
				margin-right: $gutterSmall;
			}

			&:nth-child(n+3) {
				margin-top: $base1;
			}
		}
	}


	&__label {
		@include font(base);
		position: relative;

		&--checkbox {
			cursor: pointer;
			display: inline-flex;
			align-items: center;
		}

		.filters .field--checkbox &,
		.filters .field--radio & {
			@include ease(background-color opacity, $hoverDuration);
			cursor: pointer;
			display: inline-block;
			background-color: $colorBgInput;
			padding-left: $baseHalf;
			padding-right: $baseHalf;
		}

		.filters .field__input--checkbox:checked + &,
		.filters .field__input--radio:checked + & {
			background-color: $colorBgInverted;
			color: $colorInverted;
		}

		html:not(.mobile) .filters &:hover {
			opacity: 0.5;
		}


		&--radio {
			cursor: pointer;
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}

		&--reset {
			@include fadeOut();
			cursor: pointer;
			margin-left: $base1;
			margin-right: $base2;

			@include media($mediumLayout) {
				margin-left: $base2;
				margin-right: $base3;
			}

			.field__input--text:valid ~ & {
				@include fadeIn();

				@include onHover() {
					color: $hoverColor;
				}
			}

			.field__input--text:valid ~ .field__input--reset:focus ~ & {
				outline-color: currentColor;
				outline-style: solid;
				outline-width: $inputBorderSize;
			}
		}
	}


	&__labelValue {
		.field--required > .field__content > .field__label &::after,
		.field--required > .field__content > .field__inputWrapper > .field__label &::after {
			content: '*';
		}

		.field--radio &::before {
			content: '';
			position: absolute;
			height: 100%;
			width: $base3;
			left: -$base3;
			top: 0;
		}

		.filters .field--checkbox &,
		.filters .field--radio & {
			@include font('detail');
			display: inline-block;

			.html.webKit.safari & {
				vertical-align: text-top;
			}
		}
	}


	&__text {
		margin-left: $base3;
	}


	&__link {
		display: block;
		font-weight: bold;
		margin-left: $base3;
		padding-bottom: $base1;
		text-decoration: none;

		// @include onHover {
		// 	color: $colorBase;
		// }
	}


	&__searchIcon {
		color: $colorInputPlaceholder;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}


	&__clearLink {
		cursor: pointer;
		color: $colorInputPlaceholder;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		display: none;

		&.js-visible {
			display: block;
		}

		span[data-type="svg"] {
			width: $base * 1.6;
		}
	}


	&__helpLink {
		color: $colorBase;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		&.js-hidden {
			display: none;
		}
	}


	&__helpLinkLabel {
		@include font('detail');
		color: $colorBase;
	}


	&__selectIcon {
		color: $colorInputPlaceholder;
		position: absolute;
		right: $base1;
		top: 50%;
		transform: translateY(-50%);
	}
}
