.pagination {
	&--artworks {
		margin-top: $base5;

		@include media($mediumLayout) {
			margin-top: $base8;
		}

		@include media($xLargeLayout) {
			margin-top: $base10;
		}
	}


	&__item {
		& + & {
			margin-left: $base2 + $baseHalf;

			@include media($largeLayout) {
				margin-left: $base3;
			}
		}

		&--nav {
			flex-grow: 1;

			&:last-child {
				text-align: right;
			}
		}

		.pagination--artworks &--nav {
			position: absolute;
			flex-grow: 0;
		}

		.pagination--artworks &--next {
			right: 0;
		}

		.pagination--artworks &--prev {
			left: 0;
		}

		&--indicator {
			flex-grow: 0;
		}

		.pagination__items--numbers & {
			margin-bottom: $base1;
		}
	}


	&__link {
		&--pagination {
			border-bottom: solid $borderSizeAlternate $colorBase;
		}

		&--current {
			@include font('thirdHeadline');
			border-bottom: none;
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}
}
