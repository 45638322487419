.filters {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: 100%;

		&.js-mobile-hidden {
			display: none;

			@include media($mediumLayout) {
				display: block;
			}
		}

		@include media($mediumLayout) {
			width: staticColumnSize(4, 12, $gutterMedium);
			margin-bottom: $base5;
		}

		@include media($largeLayout) {
			width: staticColumnSize(4, 12, $gutterLarge);
			margin-bottom: $base5;
		}


		&:not(:nth-child(3n)) {
			@include media($mediumLayout) {
				margin-right: $gutterMedium;
			}

			@include media($largeLayout) {
				margin-right: $gutterLarge;
			}
		}
	}
}
