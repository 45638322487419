.breadcrumbs {
	font-size: 0;	// it removes the space between inline-block elements, the actual font size is set inside
	// padding: 0 0 $base 0;


	&__item {
		display: inline-block;

		&:not(:last-child)::after {
			padding-right: $baseHalf;
		}


		&::after {
			@include font('detail');
			content: '\002F';
			display: inline-block;
			padding-left: $baseHalf;
		}
	}


	&__label {
		@include ease(border-color color, $hoverDuration);
		@include font('detail');
		color: $colorBase;
		border-bottom: 1px transparent solid;

		@include onHover('.breadcrumbs__link', '&') {
			color: $hoverColor;
			border-bottom: 1px $hoverColor solid;
		}
	}


	&__link {
		text-decoration: none;
	}
}
