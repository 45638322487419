.anchor {
	display: block;
	position: relative;
	top: -($logoHeightSmall + $headerSpacingSmall * 2);
	visibility: hidden;

	@include media($xSmallLayout) {
		top: -($logoHeightMedium + $headerSpacingMedium * 2);
	}

	@include media($smallLayout) {
		top: -($logoHeightMedium + $headerSpacingLarge * 2);
	}

	@include media($mainMenuSwitchBreakpoint) {
		top: -($logoHeightLarge + $headerSpacingLarge * 2);
	}
}
