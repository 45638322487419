.preview {
	.block--colSpan12 & {
		@include media($columnsBreakpoint) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}


	&__header {
		.block--vAlignJustified & {
			flex-grow: 1;
		}
	}


	&__info {
		display: flex;
		flex-direction: column;

		.block--vAlignBegin &,
		.block--vAlignJustified & {
			justify-content: flex-start;
		}

		.block--vAlignEnd & {
			justify-content: flex-end;
		}

		.block--vAlignCenter & {
			justify-content: center;
		}


		.block--colSpan12 & {
			@include media($columnsBreakpoint) {
				width: staticColumnSize(1, 3, $gutterMedium);
			}
		}
	}
}
