.galleryItem {

	@include media($smallLayout) {
		width: staticColumnSize(6, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		width: staticColumnSize(6, 12, $gutterLarge);
	}

	&:nth-child(odd) {
		@include media($smallLayout) {
			margin-right: $gutterMedium;
		}

		@include media($xLargeLayout) {
			margin-right: $gutterLarge;
		}
	}

	& + & {
		margin-top: $base3;

		@include media($smallLayout) {
			margin-top: 0;
		}
	}

	&:not(:first-of-type) + & {

		@include media($smallLayout) {
			margin-top: $base3;
		}
	}


	&__link {
		@include ease(opacity, $hoverDuration);
		color: currentColor;
		display: block;
		text-decoration: none;
		pointer-events: none;

		@include media($mediumLayout) {
			pointer-events: all;
		}

		@include onHover() {
			opacity: $imageOpacity;
		}
	}
}
