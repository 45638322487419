.news-tags {
	display: flex;
	flex-wrap: wrap;

	& > li {
		width: staticColumnSize(6, 12, $gutterSmall);

		&:nth-child(odd) {
			margin-right: $gutterSmall;
		}

		&:nth-child(n+3) {
			margin-top: $base1;
		}
	}

	& > li > a {
		@include font('detail');
		text-decoration: none;
		color: $colorBase;
		position: relative;
		@include ease(background-color opacity, $hoverDuration);
		display: inline-block;
		background-color: $colorBgInput;
		padding-left: $baseHalf;
		padding-right: $baseHalf;

		&.active {
			background-color: $colorBgInverted;
			color: $colorInverted;
		}

		html:not(.mobile) &:hover {
			opacity: 0.5;
		}

	}
}
