.tx-fp-newsletter {
	a {
		@include app-link();
		text-decoration: underline;

		&:focus {
			outline: $colorBase solid 1px;
		}
	}


	label {
		@include font('base');
	}

	input.btn {
		@include ease(background-color border-color, $fadeDuration);
		@include font('thirdHeadline');
		background-color: $colorDisabled;
		border-color: $colorDisabled;
		color: $colorInverted;
		cursor: inherit;
		width: auto;

		@include onHover() {
			background-color: $colorDisabled;
		}
	}

	input[type="email"]:valid ~ input[type="checkbox"]:checked ~ input.btn {
		cursor: pointer;
		background-color: $colorBase;
		border-color: $colorBase;

		@include onHover() {
			border-color: $hoverColor;
			background-color: $hoverColor;
		}

		&:focus {
			border-color: $hoverColor;
			background-color: $hoverColor;
		}
	}

	input {
		@include font('base');
		background-color: $colorBgInput;
		border: 0;
		padding: $base $base * 0.8;
		border-radius: 0;
		color: $colorBase;
		width: 100%;

		&::placeholder {
			color: $colorInputPlaceholder;
		}
	}

	input[type="checkbox"] {
		appearance: none;
		padding: 0;
		background-color: transparent;
		position: relative;
		width: $base2;
		height: $base * 1.6;

		@include media($mediumLayout) {
			height: $base * 1.8;
		}

		&::before {
			content: '';
			background-image: url(./../img/icons/20-checkbox.svg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			height: $checkboxSize;
			position: absolute;
			top: 0;
			vertical-align: middle;
			width: $checkboxSize;
			visibility: visible;
		}

		&::after {
			@include ease(opacity, $duration2);
			content: '';
			background-image: url(./../img/icons/20-checkbox-checked.svg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			vertical-align: middle;
			width: $checkboxSize;
			height: $checkboxSize;
			visibility: hidden;
		}

		&:checked::after {
			visibility: visible;
		}

		input:focus + & {
			outline-color: $colorInverted;
			outline-width: $inputBorderSize;
			outline-style: solid;
		}
	}


	label + input {
		margin-top: $baseHalf;
	}

	input + label,
	br + label {
		display: block;
		margin-top: $base2;
	}

	input + input[type="checkbox"] {
		display: inline-block;
		margin-top: $baseHalf;
	}

}
