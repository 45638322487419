.slide {
	@include ease(opacity visibility, $duration4);
	@include fadeOut();
	outline: 0;
	user-select: text;
	z-index: 1;
	height: 100%;

	&.js-current {
		@include fadeIn();
		z-index: 100;
	}

	html.no-js &:target {
		@include fadeIn();
		z-index: 101;
	}


	&__caption {
		@include font(caption);
		color: $colorBgBase;
		left: 0;
		padding: $base2 0 0 0;
		text-align: center;
		width: 100%;
	}

	&__content {
		@include app-container();
		@include staticColumns();
		align-items: flex-end;
		height: 100%;
		// padding-bottom: $base3 + $galleryNavigationIconSize + $base3;
		position: relative;
		z-index: 2;

		@include media($largeLayout) {
			padding-bottom: $base5;
		}
	}


	&__image {
		backface-visibility: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.block--img {
			flex-grow: 1;
			position: relative;
			width: 100%;
		}
	}
}
