.descendantList {
	display: flex;


	&__contentContainer {
		display: none;
	}

	&__contentWrapper {
		@include fadeOut($fadeDuration);
		@include ease(opacity visibility, $fadeDuration);
		background-color: $colorBgBase;
		border: solid 1px $colorBase;
		margin-bottom: $base3;
		min-width: 30vw;
		padding: $descendantListTopSpacing $base4 $base3 $base2;
		pointer-events: none;
		position: relative;
		z-index: 1;

		&.js-visible {
			will-change: opacity, visibility;
			pointer-events: all;
			@include fadeIn($fadeDuration);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(8, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(6, 14, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 14, $gutterLarge);
		}
	}


	&__definitionTerm {
		width: staticColumnSize(3, 12, $gutterSmall);
		margin-right: $gutterSmall;


		@include media($smallLayout) {
			width: staticColumnSize(2, 12, $gutterMedium);
			margin-right: $gutterMedium;
		}

		@include media($largeLayout) {
			width: staticColumnSize(2, 14, $gutterMedium);
			margin-right: $gutterMedium;
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(2, 14, $gutterLarge);
			margin-right: $gutterLarge;
		}
	}


	&__definitionDetail {
		width: staticColumnSize(9, 12, $gutterSmall);
		margin-left: 0;


		@include media($smallLayout) {
			width: staticColumnSize(10, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(12, 14, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(12, 14, $gutterLarge);
		}
	}


	&__item {
		& + & {
			margin-top: $baseHalf;

			@include media($largeLayout) {
				margin-top: $base1;
			}
		}
	}


	&__list {
		@include media($smallLayout) {
			@include app-verticalColumns(2, $gutterMedium, balance);
		}

		@include media($largeLayout) {
			@include app-verticalColumns(3, $gutterMedium, balance);
		}


		@include media($xLargeLayout) {
			@include app-verticalColumns(3, $gutterLarge, balance);
		}

		.descendantList--earlierPrograms & {
			@include media($largeLayout) {
				@include app-verticalColumns(2, $gutterMedium, balance);
			}

			@include media($xLargeLayout) {
				@include app-verticalColumns(2, $gutterLarge, balance);
			}
		}

		.descendantList--catalog & {
			@include app-verticalColumns(1, $gutterMedium, balance);

			@include media($largeLayout) {
				@include app-verticalColumns(2, $gutterMedium, balance);
			}

			@include media($xLargeLayout) {
				@include app-verticalColumns(2, $gutterLarge, balance);
			}
		}
	}
}
