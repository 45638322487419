.searchResults {
	width: 100%;

	a {
		@include ease(color, $hoverDuration);
		color: $colorBase;
		text-decoration: none;

		@include onHover() {
			color: $hoverColor;
		}

		&:focus {
			outline: solid $borderSize $hoverColor;
		}
	}

	&__path {
		font-family: $fontFamilyThird;
		margin-top: $baseHalf;
	}


	&__pagination {
		display: flex;
		margin-bottom: $base1;

		&.tx-indexedsearch-browselist-currentPage {
			flex-shrink: 0;

			a {
				display: flex;
				font-family: $fontFamilySecond;
			}
		}

		&--numbers {
			border-bottom: 1px solid $borderColor;
		}

		&--prev {
			flex-grow: 1;

			a {
				visibility: hidden;
				position: relative;
			}

			a::before {
				content: '';
				background-image: url(/assets/img/logos/16-gallery-arrow-left.svg);
				background-position: center;
				background-repeat: no-repeat;
				height: $legacyIconHeight;
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				visibility: visible;
				width: $legacyIconWidth;
			}
		}

		&--next {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;

			a {
				visibility: hidden;
				position: relative;
			}

			a::before {
				content: '';
				background-image: url(/assets/img/logos/16-gallery-arrow-right.svg);
				background-position: center;
				background-repeat: no-repeat;
				height: $legacyIconHeight;
				left: 0;
				position: absolute;
				top: 50%;
				transform: translate(-100%, -50%);
				visibility: visible;
				width: $legacyIconWidth;
			}
		}

		&:not(:last-of-type) {
			margin-right: $base2 + $baseHalf;

			@include media($largeLayout) {
				margin-right: $base3;
			}
		}
	}


	.tx-indexedsearch-whatis {
		@include font('baseAlternate');
	}


	.tx-indexedsearch-sw {
		font-family: $fontFamilyFourth;
	}


	.tx-indexedsearch-redMarkup {
		font-family: $fontFamilySecond;
	}

	.tx-indexedsearch .tx-indexedsearch-browsebox {
		display: none;
	}


	.tx-indexedsearch .tx-indexedsearch-browsebox:last-of-type {
		display: block;

		& > p {
			@include font('detail');
		}

		& > p strong {
			font-family: $fontFamilyMain;
			font-weight: normal;
		}

		.browsebox {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-top: $base5;
		}
	}


	.tx-indexedsearch-res {
		margin-top: $base3;

		@include media($largeLayout) {
			margin-top: $base5;
		}

		> .tx-indexedsearch-res {
			@include media($smallLayout) {
				width: staticColumnSize(10, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(9, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
			}
		}

		.tx-indexedsearch-res + .tx-indexedsearch-res {
			margin-top: $base2;
			border-top: solid $borderSize $borderColorAlternate;
			padding-top: $base2;

			@include media($largeLayout) {
				margin-top: $base3;
				padding-top: $base3;
			}
		}
	}


	.tx-indexedsearch-title {
		@include font('thirdHeadline');
		padding-right: $base2;

		a {
			@include ease(border-color color, $hoverDuration);
			text-decoration: none;
			color: currentColor;
			border-bottom: 1px currentColor solid;

			@include onHover() {
				color: $hoverColor;
				border-bottom: 1px $hoverColor solid;
			}
		}
	}


	.tx-indexedsearch-percent {
	}


	.tx-indexedsearch-descr {
		@include font('detail');
		padding-top: $base1;
	}


	.tx-indexedsearch-info {
		@include font('detail');
		padding-top: $baseHalf;

		@include media($mediumLayout) {
			padding-top: $base2;
		}
	}


	.tx-indexedsearch-path {
		color: $colorBase;

		a {
			@include ease(border-color color, $hoverDuration);
			text-decoration: none;
			color: currentColor;
			border-bottom: 1px transparent solid;

			@include onHover() {
				color: $hoverColor;
				border-bottom: 1px $hoverColor solid;
			}
		}
	}


	.tx-indexedsearch-icon {
		display: none;
	}
}
