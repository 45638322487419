.imageSlider {

	@include media($mediumLayout) {
		overflow: hidden;
	}



	&__items {
		@include staticColumns();
		user-select: none;
		text-decoration: none;
		// flex-direction: column;
		// height: 400px;
	}


	&__item {
		// height: 400px;
		// display: flex;
		// align-items: flex-end;
		// width of the item is excluding the side padding and half the padding for distance between items
		width: calc(100% - #{$containerPaddingXSmall});

		@include media($xSmallLayout) {
			width: calc(100% - #{$containerPaddingSmall});
		}

		@include media($smallLayout) {
			width: calc(100% - #{$containerPaddingMedium});
		}

		@include media($mediumLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
		}


		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
		}


		&:not(:last-of-type) {
			margin-right: $containerPaddingXSmall / 2;

			@include media($xSmallLayout) {
				margin-right: $containerPaddingSmall / 2;
			}

			@include media($smallLayout) {
				margin-right: $containerPaddingMedium / 2;
			}

			@include media($mediumLayout) {
				margin-right: $gutterMedium;
			}

			@include media($xLargeLayout) {
				margin-right: $gutterLarge;
			}
		}

		&:last-of-type {
			margin-right: $containerPaddingXSmall;

			@include media($xSmallLayout) {
				margin-right: $containerPaddingSmall;
			}

			@include media($smallLayout) {
				margin-right: $containerPaddingMedium;
			}

			@include media($mediumLayout) {
				margin-right: 0;
			}
		}
	}


	&__navDraggable {
		@include storeValuesForJs((visibleItemAmount: 1));
		overflow: hidden;
		position: relative;
		// stretch the nav to be bigger than its right side padding
		width: calc(100% + #{$containerPaddingXSmall});
		margin-right: - $containerPaddingXSmall;

		@include media($xSmallLayout) {
			width: calc(100% + #{$containerPaddingSmall});
			margin-right: - $containerPaddingSmall;
		}

		@include media($smallLayout) {
			width: calc(100% + #{$containerPaddingMedium});
			margin-right: - $containerPaddingMedium;
		}

		@include media($mediumLayout) {
			@include storeValuesForJs((visibleItemAmount: 2));
			width: 100%;
			margin-right: 0;
		}
	}


	&__nav {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: $base3;


		@include media($largeLayout) {
			margin-left: auto;
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterLarge);
		}
	}
}
