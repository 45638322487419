.headerTitle {
	@include media($mediumLayout) {
		display: flex;
		flex-direction: column;
	}

	@include media($largeLayout) {
		flex-direction: row;
		align-items: center;
	}

	&__content {

		@include media($smallLayout) {
			width: staticColumnSize(11, 12, $gutterMedium);
		}

		// @include media($mediumLayout) {
		// 	width: staticColumnSize(11, 12, $gutterLarge);
		// }

		@include media($largeLayout) {
			width: staticColumnSize(10, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(9, 12, $gutterLarge);
		}

		.headerTitle--alternate & {
			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
				margin-right: $gutterMedium;
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
				margin-right: $gutterLarge;
			}
		}

		.headerTitle--alternate &:not(:last-of-type) {
			margin-bottom: $base3;

			@include media($mediumLayout) {
				margin-bottom: $base5;
			}

			@include media($largeLayout) {
				margin-bottom: 0;
			}
		}

		.headerTitle--alternate &--image {
			width: 100%;
			@include media($smallLayout) {
				display: flex;
				justify-content: flex-end;
			}

			@include media($largeLayout) {
				width: staticColumnSize(6, 12, $gutterMedium);
				margin-right: 0;
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(6, 12, $gutterLarge);
			}
		}
	}


	&__headline {
		@include font('firstHeadlineContent');
	}


	&__imageWrapper {
		@include media($xSmallLayout) {
			padding-left: $gutterSmall;
		}

		@include media($smallLayout) {
			padding-left: 0;
			width: staticColumnSize(10, 12, $gutterMedium);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(9, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: 100%;
		}
	}
}
