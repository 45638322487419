.gallery {
	@include storeValuesForJs((modalEnabled: false));

	@include media($smallLayout) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	@include media($mediumLayout) {
		@include storeValuesForJs((modalEnabled: true));
	}
}


