.introduction {
	position: relative;
	padding-top: $base * 20;
	padding-bottom: $base3;
	overflow: hidden;


	@include media($xSmallLayout) {
		@include app-container();
		padding-top: $base * 30;
		padding-bottom: $base3;
	}

	@include media($smallLayout) {
		padding-top: $base * 34;
		padding-bottom: $base5;
	}

	@include media($mediumLayout) {
		padding-top: $base * 35;
		padding-bottom: 0;
		padding-left: 0;
	}


	@include media($largeLayout) {
		@include app-container();
		padding-bottom: 0;
		padding-top: $base4;
	}

	@include media($largeLayout) {
		padding-bottom: 0;
		padding-top: $base * 40;
	}


	&__textBox {
		position: relative;
	}


	&__imageWrapper {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;

		@include media($largeLayout) {
			@include app-container();
		}
	}
}
