.indication {
	@include storeValuesForJs((indicatorOffset: 10));
	background: $colorIndicatorBg;
	display: block;
	height: $indicatorHeight;
	overflow: hidden;
	position: relative;
	width: 100%;
	opacity: 0.5;

	&__indicator {
		background: $colorBase;
		height: $indicatorHeight;
		left: 0;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		width: calc(100% + #{$indicatorOffset});
	}
}
