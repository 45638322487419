.textBox {
	background-color: $colorBgBase;
	padding-top: $base1;
	padding-left: $base1;
	padding-right: $base1;
	padding-bottom: $base4;
	margin-left: $base1;
	margin-right: $base1;

	@include media($xSmallLayout) {
		padding-left: $base2;
		padding-right: $base2;
		padding-bottom: $base3;
		margin-left: 0;
		margin-right: 0;
		max-width: $base * 40;
	}

	@include media($smallLayout) {
		max-width: none;
		padding-top: $base2;
		padding-right: 0;
		padding-left: $base2 + $baseHalf;
		width: staticColumnSize(8, 12, $gutterMedium);
	}

	@include media($mediumLayout) {
		padding-top: $base3;
		padding-left: $base5;
		padding-bottom: 0;
		width: staticColumnSize(10, 12, $gutterMedium);
	}

	@include media($largeLayout) {
		padding-top: $base3;
		padding-bottom: 0;
		padding-left: 0;
		width: 61%;
	}

	@include media($xLargeLayout) {
		padding-top: $base4;
	}


	&__content {
		@include media($smallLayout) {
			padding-right: $base5;
		}

		@include media($mediumLayout) {
			padding-right: 0;
			width: staticColumnSize(9, 10, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(8, 8, $gutterMedium);
			// padding-right: $base8;
		}

		// @include media($xLargeLayout) {
		// 	width: staticColumnSize(6, 8, $gutterLarge);
		// }
	}

	&__title {
		@include font('firstHeadlineHighlight');
		margin-bottom: $base1;

		@include media($xLargeLayout) {
			margin-bottom: $base2;
		}
	}

	&__text {
		@include font('base');

		@include media($smallLayout) {
			padding-right: $base5;
		}

		@include media($mediumLayout) {
			padding-right: 0;
			width: staticColumnSize(8, 9, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(6, 8, $gutterMedium);
		}
	}
}
