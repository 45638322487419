.resultList {
	margin-top: $base2;

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: $base1;

		@include media($mediumLayout) {
			margin-top: 0;
		}

		.resultList.js-layout-alternate & {
			@include media($mediumLayout) {
				margin-top: $base2;
			}
		}
	}


	&__details {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include media($mediumLayout) {
			flex-direction: row;
			width: staticColumnSize(8, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			width: staticColumnSize(8, 12, $gutterLarge);
		}

		.resultList.js-layout-alternate & {
			@include media($mediumLayout) {
				width: 100%;
			}
		}
	}


	&__detail {
		& + & {
			margin-top: $baseHalf;

			@include media($mediumLayout) {
				margin-top: 0;
			}
		}

		.resultList.js-layout-alternate & + & {
			@include media($mediumLayout) {
				margin-top: 0;
			}
		}

		@include media($mediumLayout) {
			width: staticColumnSize(4, 8, $gutterMedium);
			margin-right: $gutterMedium;
		}

		@include media($largeLayout) {
			width: staticColumnSize(4, 8, $gutterLarge);
			margin-right: $gutterLarge;
		}

		&--dimension {
			@include media($mediumLayout) {
				width: staticColumnSize(5, 8, $gutterMedium);
				margin-right: $gutterMedium;
			}

			@include media($largeLayout) {
				width: staticColumnSize(5, 8, $gutterLarge);
				margin-right: $gutterLarge;
			}
		}

		.resultList.js-layout-alternate & {
			@include media($mediumLayout) {
				margin-right: 0;
			}
		}

		.resultList.js-layout-alternate &:nth-child(even) {
			@include media($mediumLayout) {
				margin-left: $gutterMedium;
			}

			@include media($largeLayout) {
				margin-left: $gutterLarge;
			}
		}
	}


	&__loading {
		@include fadeOut($fadeDuration);
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: transparentize($color: $colorBgBase, $amount: 0.3);
		left: 0;
		bottom: 0;
		padding-top: $base5;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;

		&.js-visible {
			@include fadeIn($fadeDuration);
		}
	}


	&__item {
		display: flex;
		flex-direction: column;
		width: staticColumnSize(12, 12, $gutterSmall);
		position: relative;

		@include media($smallLayout) {
			margin-bottom: $base2;
			width: staticColumnSize(6, 12, $gutterSmall);
		}

		@include media($mediumLayout) {
			flex-direction: row;
			padding-top: $base3;
			padding-bottom: $base3;
			margin-bottom: 0;
			width: 100%;
		}

		&--emptyResult {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		&:last-child {
			padding-bottom: 0;
		}


		&:nth-child(odd) {
			@include media($smallLayout) {
				margin-right: $gutterSmall;
			}

			@include media($mediumLayout) {
				margin-right: 0;
			}
		}


		& + & {
			padding-top: $base2;
			margin-top: $base2;
			border-top: solid 2px $colorResultsListBorder;

			@include media($smallLayout) {
				padding-top: 0;
				margin-top: 0;
				border-top: none;
			}

			@include media($mediumLayout) {
				border-top: solid 2px $colorResultsListBorder;
				padding-top: $base3;
			}
		}

		.resultList.js-layout-alternate & {
			@include media($mediumLayout) {
				flex-direction: column;
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				flex-direction: column;
				width: staticColumnSize(6, 12, $gutterLarge);
			}
		}

		.resultList.js-layout-alternate & + & {
			@include media($mediumLayout) {
				border-top: none;
			}
		}

		.resultList.js-layout-alternate &:nth-child(odd) {
			@include media($mediumLayout) {
				margin-right: $gutterMedium;
			}

			@include media($largeLayout) {
				margin-right: $gutterLarge;
			}
		}

		.resultList.js-layout-alternate &:nth-child(1n + 3) {
			@include media($mediumLayout) {
				border-top: solid 2px $colorResultsListBorder;
			}
		}
	}


	&__imgWrapper {
		width: 100%;

		@include media($mediumLayout) {
			width: staticColumnSize(2, 12, $gutterMedium);
			margin-right: $gutterMedium;
		}

		@include media($largeLayout) {
			width: staticColumnSize(2, 12, $gutterLarge);
			margin-right: $gutterLarge;
		}

		.resultList.js-layout-alternate & {
			flex-grow: 1;

			@include media($mediumLayout) {
				flex-grow: 0;
				margin-right: 0;
				width: 100%;
			}
		}
	}

	&__icon {
		span[data-type="svg"] svg {
			border: solid $colorLoadingStroke 2px;

			@keyframes dash {
				from {
					stroke-dashoffset: 1000;
				}

				to {
					stroke-dashoffset: 850;
				}
			}
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			animation: dash 2s infinite linear both;
		}
	}


	&__list {
		display: flex;
		flex-wrap: wrap;
	}


	&__text {
		@include font('detail');

		&--highlight {
			@include font('thirdHeadline');
			margin-bottom: $base1;
		}

		& + & {
			margin-top: $baseHalf;

			@include media($mediumLayout) {
				margin-top: $base1;
			}
		}
	}
}
