// Style for User Generated Content
.ugc {
	a {
		@include ease(border-color, $duration3);
		border-bottom: 1px $colorUnderline solid;
		color: $colorBase;
		text-decoration: none;
		word-break: break-word;

		@include onHover() {
			border-bottom: 1px $hoverColorAlternate solid;
			color: hoverColor;
		}

		@include media($smallLayout) {
			word-break: initial;
		}
	}


	q,
	blockquote {
		quotes: "\00BB" "\00AB";
	}


	blockquote {
		@include font(quote);
		margin: 0;
		padding-left: $base3;
		border-left: $borderSize solid currentColor;

		> p:first-child::before {
			content: open-quote;
		}

		> p:last-of-type::after {
			content: close-quote;
		}
	}


	cite,
	i {
		font-family: $fontFamilyThird;
		font-size: inherit;
		line-height: inherit;
		font-style: normal;
	}

	strong {
		font-family: $fontFamilySecond;
		font-weight: normal;
		font-size: inherit;
		line-height: inherit;
		font-style: normal;
	}

	cite strong,
	strong cite {
		font-family: $fontFamilyFourth;

	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}


	q {
		font-style: italic;
	}


	dl {
		margin: 0;
	}


	dd {
		margin: 0;

		@include media($largeLayout) {
			max-width: staticColumnSize(5, 6, $gutterLarge);
		}
	}


	dt {
		@include font(secondHeadline);
		display: block;
		font-weight: normal;
	}

	.ugc &__definitionList--alternate {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		@include media($smallLayout) {
			flex-direction: row;
		}

		dt {
			@include font(base);
			display: block;
			font-weight: normal;
		}

		dd {
			@include font(secondHeadline);
			display: block;
			font-weight: normal;
			min-width: 100%;
		}

		dt + dd {
			margin-top: 0;
		}
	}

	&__definitionList--alternate .ugc__definitionListWrapper {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include media($smallLayout) {
			width: staticColumnSize(3, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(3, 6, $gutterLarge);
		}


		&:nth-child(odd) {
			@include media($smallLayout) {
				margin-right: $gutterMedium;
			}

			@include media($xLargeLayout) {
				margin-right: $gutterLarge;
			}
		}

		& + .ugc__definitionListWrapper:nth-child(n+3) {
			@include media($smallLayout) {
				margin-top: $base2;
			}
		}

		& + .ugc__definitionListWrapper {
			margin-top: $base1;

			@include media($smallLayout) {
				margin-top: 0;
			}
		}
	}

	i {
		font-style: italic;
	}


	// this should never be used inside content,
	h1 {
		@include font(firstHeadline);
	}


	h2 {
		@include font(secondHeadline);
	}


	h3 {
		@include font(thirdHeadline);
	}


	h4,
	h5,
	h6 {
		@include font(thirdHeadline);
	}

	p {
		@include font('base');
	}


	hr {
		background-color: currentColor;
		border: 0;
		height: $hrSize;
		margin: 0;
		width: 100%;
	}


	li {
		margin: 0;
	}


	ol {
		margin: 0 0 0 $base3;
		list-style: none;
	}


	ul {
		margin: 0 0 0 $base3;
		list-style-type: circle;
	}

	ul > li {
		position: relative;

		&::marker {
			content: '―';
			letter-spacing: $base1;
		}
	}

	html.safari & {
		ul {
			list-style-type: none;
		}

		ul > li::before {
			content: '―';
			position: absolute;
			left: -$base3;
		}
	}


	ol > li {
		counter-increment: item;
		padding-left: 0.4em;

		&::before {
			font-family: $fontFamilySecond;
			display: inline-block;
			width: $base2;
			margin-left: - $base3 - $baseHalf;
			margin-right: $base1;
			text-align: left;
			content: counter(item) ".";
		}
	}


	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul,
	dl,
	blockquote {
		max-width: $textMaxWidth;
	}


	* + a > figure {
		margin-top: 1em;
	}


	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}


	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + ol,
	* + ul,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: 1em;
	}


	// space between list items
	li + li {
		margin-top: $base;
	}


	// special style between definition list items
	dd + dt {
		margin-top: $base5;
	}

	dt + dd {
		margin-top: $base2;
	}


	// special margin around the blockquote
	* + blockquote,
	blockquote + *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
		margin-top: $base5;
	}

	// special margin around hr separator
	* + hr,
	hr + * {
		margin-top: $base2;
	}

	*.ugc__text {
		&--alternate {
			@include font(baseAlternate);
		}
	}

	// th content added dynamically in each cell in order to be displayed on small screens
	[data-nested-header] {
		font-weight: bold;

		@include media($smallLayout) {
			display: none;
		}
	}

	table {
		display: block;
		width: 100%;

		@include media($smallLayout) {
			display: table;
			border-collapse: collapse;
			border-spacing: 0;
		}
	}


	tbody,
	caption {
		display: block;

		@include media($smallLayout) {
			display: table-row-group;
		}
	}


	caption {
		@include font(caption);
		caption-side: bottom;
		border-top: $tableCellBorderSize solid $colorTableCellBorder;
		padding: $baseHalf 0 0 0;
		text-align: right;
	}


	tr {
		display: block;
		padding: $base1 0;

		&:not(:first-of-type) {
			border-bottom: solid $tableHeaderBorderSize $colorTableCellBorder;
		}

		@include media($smallLayout) {
			display: table-row;
		}
	}

	th {
		@include font(thirdHeadline);
		display: none;
		text-align: left;


		@include media($smallLayout) {
			border-bottom: solid $tableHeaderBorderSize $colorBase;
			display: table-cell;
			vertical-align: top;
			padding: $baseHalf $base2 $base1 0;
		}

		@include media($mediumLayout) {
			padding: $baseHalf $base2 $base2 0;
		}

		&:last-child {
			@include media($smallLayout) {
				padding-right: 0;
			}
		}
	}

	td {
		display: flex;
		text-align: left;

		& > *[data-nested-header] {
			@include font(thirdHeadline);
			flex-grow: 1;
		}

		& + & {
			padding-top: $baseHalf;
		}

		@include media($smallLayout) {
			border-bottom: 2px solid $colorTableCellBorder;
			display: table-cell;
			padding: $baseHalf $base2 $baseHalf 0;
			vertical-align: top;
		}

		@include media($mediumLayout) {
			padding: $base2 $base2 $base2 0;
		}
	}


	tr:last-of-type td {
		@include media($smallLayout) {
			border-bottom: 0;
		}

		&:last-child {
			border-bottom: 0;
		}
	}

}
