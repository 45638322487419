.newsletterSubscribe {
	position: relative;

	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include media($smallLayout) {
			width: staticColumnSize(8, 12, $gutterMedium);
		}

		@include media($mediumLayout) {
			width: 100%;
		}
	}


	&__input {
		@include font('fieldDescription');
		background-color: $colorBgInverted;
		border-bottom: solid 1px $colorInverted;
		border-left: 0;
		border-right: 0;
		border-top: 0;
		border-radius: 0;
		color: $colorInverted;
		padding-bottom: $base1;
		width: 100%;

		&--checkbox {
			@include visuallyHidden();
			bottom: $base5 * 3;
			left: $base1;
		}

		&:focus-within {
			&::placeholder {
				color: transparent;
			}
		}
	}


	&__label {
		position: relative;
		margin-left: $base3;

		&--checkbox {
			margin-top: $base2;

			&::before {
				content: '';
				background-image: url(/assets/img/icons/20-checkbox.svg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				height: $checkboxSize;
				left: -$base3;
				position: absolute;
				top: 0;
				vertical-align: middle;
				width: $checkboxSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				content: '';
				background-image: url(/assets/img/icons/20-checkbox-checked.svg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				left: -$base3;
				opacity: 0;
				position: absolute;
				top: 0;
				vertical-align: middle;
				width: $checkboxSize;
				height: $checkboxSize;
			}

			input:focus + & {
				outline-color: $colorInverted;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		.newsletterSubscribe__input--checkbox:checked + &::after {
			opacity: 1;
		}
	}

	&__labelValue {
		@include font(detail);
		vertical-align: top;
	}


	&__link {
		@include font(detail);
		@include app-link(true);
		display: inline;
		color: $colorInverted;
		text-decoration: none;
		vertical-align: top;
	}
}
