.modal {
	// @include storeValuesForJs((enableScrollMonitor: false))
	@include fadeOut($duration4);
	background-color: $colorBgModalCover;
	display: none;
	height: 100vh;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 2;

	&--alternate {
		overflow-y: auto;
		// background-color: transparent;
	}

	&.js-fixed {
		position: fixed;
	}


	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}

	&__content {
		pointer-events: none;
		height: 100%;

		.modal--alternate & {
			display: flex;
			height: auto;
			min-height: 100%;
			justify-content: center;
			align-items: center;
		}
	}


	&__wrapper {
		@include app-container();
		height: 100%;
		padding-top: $modalTopSpacing;
		padding-bottom: $base2;
		position: relative;

		.modal--alternate & {
			display: flex;
			height: auto;
			min-height: 100%;
			justify-content: center;
			align-items: center;
		}
	}


	&__item {
		height: 100%;
		position: relative;
		width: 100%;
	}
}
