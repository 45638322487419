$colorBase: #1a1a1a;
$colorBgBase: #f5f5f5;
$colorInverted: $colorBgBase;
$colorBgInverted: $colorBase;
$colorAlternative: #333333;
$colorBgAlternative: #AAAAAA;

$colorBgModal: #CCCCCC;


$colorInput: $colorBase;
$colorBgInput: #e8e8e8;
$colorInputBorder: $colorBase;
$colorError: #FF0000;
$borderColor: $colorBase;
$borderColorAlternate: #dcdcdc;
$colorUnderline: #9D9D9D;
$colorDisabled: #9D9D9D;
$colorIndicatorBg: #dcdcdc;
$colorInputPlaceholder: #919191;
$colorResultsListBorder: #e8e8e8;

$colorLoadingStroke:  #1A1A1A;
$colorImgPlaceholder:  #e8e8e8;


$colorTableCellBorder: $borderColorAlternate;

$colorBgModalCover: transparentize($colorBgInverted, 0.2);

$hoverColor: #8c8c8c;
$hoverColorAlternate: #454545;
$focusColor: $colorBase;
