.newsPreviews {
	&__details {
		display: flex;
		margin-bottom: $base1;
		align-items: baseline;
	}


	&__headline {
		@include font('baseAlternate');
	}


	&__imageWrapper {
		margin-bottom: $base2;

		.body.js-safari {
			height: 100%;
		}

		@include media($smallLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
			margin-bottom: 0;
			margin-right: $gutterMedium;
		}

		@include media($largeLayout) {
			width: 100%;
			margin-bottom: $base2;
		}
	}

	&__list {
		@include media($largeLayout) {
			display: flex;
			flex-wrap: wrap;
		}
	}


	&__listItem {
		margin-bottom: $base2;

		@include media($smallLayout) {
			display: flex;
		}

		@include media($mediumLayout) {
			margin-bottom: $base3;
		}


		@include media($largeLayout) {
			width: staticColumnSize(4, 12, $gutterMedium);
			flex-direction: column;
			margin-bottom: 0;
		}


		@include media($xLargeLayout) {
			width: staticColumnSize(4, 12, $gutterLarge);
			flex-direction: column;
			margin-bottom: 0;
		}

		& + & {
			padding-top: $base2;
			border-top: solid  $borderSize $borderColorAlternate;

			@include media($mediumLayout) {
				padding-top: $base3;
			}

			@include media($largeLayout) {
				border-top: none;
				padding-top: 0;
			}
		}

		// NOTE: apply on everyone exept every third
		&:not(:nth-child(3n)) {
			@include media($largeLayout) {
				margin-right: $gutterMedium;

			}

			@include media($xLargeLayout) {
				margin-right: $gutterLarge;
			}
		}

		// NOTE: apply on everyone exept first three
		&:nth-child(n+4) {
			@include media($largeLayout) {
				border-top: solid  $borderSize $borderColorAlternate;
				margin-top: $base3;
				padding-top: $base3;
			}
		}
	}


	&__textContent {
		.newsPreviews__listItem--alternate & {
			@include media($smallLayout) {
				width: staticColumnSize(8, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: 100%;
			}
		}

		@include media($smallLayout) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($largeLayout) {
			display: block;
			width: 100%;
		}
	}
}
