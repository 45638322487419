.searchSuggestions {
	// .tx-indexedsearch-autocomplete {
	// }

	.tx-indexedsearch-autocomplete-words {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		li {
			@include ease(border-color color, $hoverDuration);
			@include font('base');
			cursor: pointer;
			display: inline;

			@include onHover() {
				color: $hoverColor;
			}

			&:focus {
				outline: solid 1px $hoverColor;
			}
		}

		li + li {
			margin-top: $base1;
		}
	}
}
