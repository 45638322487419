.link {
	@include font('base');
	font-family: $fontFamilySecond;
	text-decoration: none;

	&--artist {
		margin-top: $base3;
	}

	&--default {
		@include app-link();

		.imageContentGallery &,
		.application--jobs & {
			margin-bottom: $base1;

			@include media($mediumLayout) {
				margin-bottom: $base2;
			}
		}

		.newsPreviews & {
			margin-bottom: $base1;
		}
	}

	&--imageLink {
		@include ease(opacity, $hoverDuration);
		display: block;

		@include onHover() {
			opacity: $imageOpacity;
		}
	}

	&--button {
		@include app-linkButton();
	}

	&--imageWrapper {
		@include ease(opacity, $hoverDuration);
		display: block;
		text-decoration: none;

		@include onHover() {
			opacity: $imageOpacity;
		}
	}

	&--subscribe {
		@include ease(color, $hoverDuration);
		border-bottom: $colorInverted solid $borderSize;
		color: $colorInverted;
		display: block;
		font-family: $fontFamilyMain;
		font-weight: normal;
		margin-bottom: $base5;
		padding-bottom: $base1;
		width: 100%;

		@include onHover() {
			color: $hoverColor;
		}

		&:focus {
			outline: solid 1px $colorInverted;
		}
	}

	&__label {
		color: $colorBase;
		display: block;

		.link--button & {
			color: $colorInverted;
		}

		.link--title & {
			@include font('baseAlternate');
		}
	}
}
